<template lang="pug">
.space-y-4(class='min-w-[368px]')
  // Top - Logo
  .flex.justify-center
    .bg-transparent.rounded-xl.w-20.h-20.overflow-hidden.border.p-2
      img.w-full.h-full.object-cover(:src='accountLogo')
    .bg-transparent.rounded-xl.w-20.h-20.overflow-hidden.ml-8.border.p-2(
      v-if='logo'
    )
      img.w-full.h-full.object-cover(:src='logo')
  // Next step
  .text-center.text-base.font-medium(v-if='tab !== "preview"') Next steps

  // Default Screen
  .px-8(class='space-y-[12px]' v-if='step==="default"')
    // Button in more
    UButton.text-black.gap-2.w-full.border.bg-white(
        v-for='(btn,idx) in moreBtns'
        :key='idx'
        class='hover:bg-white/90'
        @click='clickNextStep(btn)'
      )
        .font-semibold {{ btn.title }}

    // Add Button
    .flex.items-center.justify-center.gap-2.cursor-pointer.select-none(
      v-if='!readonly && tab !== "preview"'
      class='hover:opacity-90'
      @click='openAdd()'
    )
      .w-5.h-5.flex.items-center.justify-center(class='bg-[#E2E8F0]')
        Icon.w-3.h-3.text-black(name='lucide:plus')
      span.text-base(class='text-[#94A3B8]') Add button

  // Thanks Screen
  PageScreenThanksScreen(
    :msg='thankMess'  
    @back="step = 'default'"
    v-else-if='step === "thanks"'
  )
  // Custom Input Screen
  PageScreenCustomInputScreen(
    :btn='selectedBtn'
    @confirm="doConfirmInput"
    @back="step = 'default'"
    v-else-if='step === "input"'
  )
  // Add / Edit Screen
  PageScreenNextStepForm(
    @add="doSave"
    @edit="doEdit"
    @cancel='doCancel'  
    @remove="doRemove" 
    :selectedBtn='selectedBtn' 
    v-if='showNextStepForm'
  )
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
const UButton = defineAsyncComponent(
  () => import('@/components/ui/button/Button.vue'),
)

const readonly = inject('readonly', false)

const route = useRoute()
const router = useRouter()

const { logo } = useCaptureLogo()
const { click: clickEvent } = useTracking()

const { tab } = useEditTab()

onMounted(() => {
  if (route.name === 'link-slug') {
    tab.value = 'preview'
  }
})

const moreBtns = ref<IButtonAction[]>([])

// ========= DO ADD/EDIT BUTTON ========
const step = ref<'default' | 'add' | 'edit' | 'thanks' | 'input'>('default')

const showNextStepForm = computed(
  () => ['add', 'edit'].includes(step.value) && tab.value === 'edit',
)

const editable = computed(
  () => tab.value === 'edit' && route.name === 'page-slug',
)

const selectedBtn = ref<IButtonAction>()
const thankMess = ref('')

function doSave(btn: IButtonAction) {
  // No selected -> add
  if (!selectedBtn.value) moreBtns.value.push(btn)
  // Else edit
  else openEdit(btn)
}

function doCancel() {
  // tab is edit & step goto default
  step.value = 'default'
}

function doRemove(newBtn: IButtonAction) {
  const id = selectedBtn.value!.id
  // find index
  const index = moreBtns.value.findIndex((btn) => btn.id === id)

  // check index
  // found -> replace by new button
  if (index === -1) return
  else {
    moreBtns.value.splice(index, 1)
  }
}

function doEdit(newBtn: IButtonAction) {
  const id = selectedBtn.value!.id
  // find index
  const index = moreBtns.value.findIndex((btn) => btn.id === id)

  // check index
  // found -> replace by new button
  if (index === -1) return
  else {
    moreBtns.value[index] = newBtn
  }
  //  moreBtns.value.splice()
}

function openAdd() {
  // tab is edit & step goto default
  step.value = 'add'
  // reset selected btn
  selectedBtn.value = undefined
}

function openEdit(btn: IButtonAction) {
  // tab is edit & step goto edit
  step.value = 'edit'
  // select button
  selectedBtn.value = btn
}

function openThanks(btn: IButtonAction) {
  // tab is edit & step goto thanks
  thankMess.value = btn.message!
  // trigger
  clickEvent(btn.message!)
  step.value = 'thanks'
}

function openUrl(url: string) {
  window.open(url, '_blank')
}

function openInput(btn: IButtonAction) {
  // tab is edit & step goto input
  step.value = 'input'
  selectedBtn.value = btn
}

function doConfirmInput() {
  thankMess.value = selectedBtn.value?.message!
  // Goto thanks
  step.value = 'thanks'
}

function clickNextStep(btn: IButtonAction) {
  switch (btn.type) {
    case 'reply':
      if (!editable.value) openThanks(btn)
      else openEdit(btn)
      break
    case 'open-page':
      if (!editable.value) {
        const url = router.resolve({
          name: 'page-slug',
          params: { slug: btn.page_id },
        })
        clickEvent(`The user has gone to the ${url} page`)
        openUrl(url.href)
      } else openEdit(btn)
      break
    case 'open-link':
      if (!editable.value) {
        clickEvent(`The user has gone to the ${btn.website_url!} page`)
        openUrl(btn.website_url!)
      } else openEdit(btn)
      break
    case 'request-input':
      if (!editable.value) openInput(btn)
      else openEdit(btn)
      break

    default:
      break
  }
}

// Support account logo
const { account } = storeToRefs(useUserStore())
const metadata = computed(() => {
  return account.value?.metadata
})

const accountLogo = computed(
  () => metadata.value?.logo || 'https://picsum.photos/200',
)
</script>

<style lang="stylus" scoped></style>
